import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { BrinkContext } from "../components/context/BrinkContext"
import Layout from "../components/Layout"
import WidgetLoader from "../components/widgets/WidgetLoader"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import queryString from "query-string"

const CmsPage = ({ data: { sanityCmsPage }, pageContext, location }) => {
  const { languageCode, setDiscountCode, addDiscount } =
    useContext(BrinkContext)
  const { widgets, pageTitle, pageDescription } = sanityCmsPage
  const { discountCode } = queryString.parse(location.search)

  useEffect(() => {
    const applyCode = async (discountCode) => {
      await addDiscount({ code: discountCode })
    }

    if (discountCode) {
      applyCode(discountCode)
      setDiscountCode(discountCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCode])

  return (
    <Layout
      meta={{
        title: pageTitle[languageCode] || pageTitle.en,
        description: pageDescription[languageCode] || pageDescription.en
      }}
      invertedHeader
      pageContext={pageContext}
    >
      <Breadcrumbs> {pageTitle[languageCode] || pageTitle.en}</Breadcrumbs>
      {widgets && widgets.length && <WidgetLoader widgets={widgets} />}
    </Layout>
  )
}

export default CmsPage

export const query = graphql`
  query ($sanityPageId: String!) {
    sanityCmsPage(_id: { eq: $sanityPageId }) {
      pageTitle {
        en
        de
      }
      pageDescription {
        en
        de
      }
      widgets {
        ...TextWidget
        ...Grid
        ...Hero
        ...ProductSlider
        ...UspWidget
        ...Countdown
        ...Image
        ...Video
        ...IconGrid
        ...Instagram
      }
    }
  }
`
